import { React, useEffect, useState } from 'react'
import { Card, Image, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Button, ButtonGroup, ButtonToolbar, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoCamera, faShareNodes, faDownload, faFileAudio, faUser, faLocation, faClock, faPerson, faArrowLeft, faAngleRight, faBook, faMessage } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone, faMapLocationDot, faMapLocation, faAddressCard, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { serverurl } from '../../providers/ServerUrl';
//import ReactDOM from "react-dom";
import './Give.css';

import { useSignal } from '@preact/signals-react';

import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { PaymentImages } from '../../components/PaymentImages';


export const Give = () => {


    /**********************************************
      GET GIVING CATEGORY DATA FROM THE API
    **********************************************/

    const [givingcategory, setGivingsCategory] = useState();


   const fetchGivingCategoryData = () => {
       return axios.get(serverurl + "/api/givingcategory")
           .then((response) => setGivingsCategory(response.data['givingcategories']));

   };

   useEffect(() => {
       fetchGivingCategoryData();
   }, [])


   /**********************************************
      POST CONTACT FORM DATA TO THE API
    **********************************************/
      const [show, setShow] = useState(false);
      const [success, setSuccess] = useState(false);
      const [errormessages, setErrorMessages] = useState('');
      const [orderid, setOrderId] = useState(false);
  
      const [givings_name, setGivingName] = useState('Dummy Name');
      const [givings_email, setGivingEmail] = useState('dummy@gmail.com');
      const [givings_pnum, setGivingPnum] = useState('09012345678');
      const [givings_type, setGivingCategory] = useState();
      const [givings_amount, setGivingAmount] = useState();

      const givingsstatus = "Paid";

      //const givings_amount  = useSignal();

      const handleInputChange = (e) => {
         setGivingAmount(e.target.value);
      }

      const handleGivingCatInputChange = (e) => {
         setGivingCategory(e.target.value);
    }

      let givingbtn = document.getElementsByClassName('givingbtn');

      const handleButtonChange50 = (e) => {
         setGivingAmount(e.target.value);
         console.log(e.target.value);

         givingbtn[0].style.backgroundColor = '#135592';
         givingbtn[0].style.color = '#fff';
         givingbtn[1].style.backgroundColor = '#fff';
         givingbtn[1].style.color = '#000';
         givingbtn[2].style.backgroundColor = '#fff';
         givingbtn[2].style.color = '#000';
         givingbtn[3].style.backgroundColor = '#fff';
         givingbtn[3].style.color = '#000';
         givingbtn[4].style.backgroundColor = '#fff';
         givingbtn[4].style.color = '#000';
         givingbtn[5].style.backgroundColor = '#fff';
         givingbtn[5].style.color = '#000';
      }
      const handleButtonChange100 = (e) => {
       setGivingAmount(e.target.value);
       console.log(e.target.value);

         
       givingbtn[0].style.backgroundColor = '#fff';
       givingbtn[0].style.color = '#000';
       givingbtn[1].style.backgroundColor = '#135592';
       givingbtn[1].style.color = '#fff';
       givingbtn[2].style.backgroundColor = '#fff';
       givingbtn[2].style.color = '#000';
       givingbtn[3].style.backgroundColor = '#fff';
       givingbtn[3].style.color = '#000';
       givingbtn[4].style.backgroundColor = '#fff';
       givingbtn[4].style.color = '#000';
       givingbtn[5].style.backgroundColor = '#fff';
       givingbtn[5].style.color = '#000';
       }

       const handleButtonChange200 = (e) => {
           setGivingAmount(e.target.value);
           console.log(e.target.value);
           
         givingbtn[0].style.backgroundColor = '#fff';
         givingbtn[0].style.color = '#000';
         givingbtn[1].style.backgroundColor = '#fff';
         givingbtn[1].style.color = '#000';
         givingbtn[3].style.backgroundColor = '#fff';
         givingbtn[3].style.color = '#000';
         givingbtn[2].style.backgroundColor = '#135592';
         givingbtn[2].style.color = '#fff';
         givingbtn[4].style.backgroundColor = '#fff';
         givingbtn[4].style.color = '#000';
         givingbtn[5].style.backgroundColor = '#fff';
         givingbtn[5].style.color = '#000';
       }
       const handleButtonChange500 = (e) => {
           setGivingAmount(e.target.value);
           console.log(e.target.value);

           
         givingbtn[0].style.backgroundColor = '#fff';
         givingbtn[0].style.color = '#000';
         givingbtn[1].style.backgroundColor = '#fff';
         givingbtn[1].style.color = '#000';
         givingbtn[3].style.backgroundColor = '#135592';
         givingbtn[3].style.color = '#fff';
         givingbtn[2].style.backgroundColor = '#fff';
         givingbtn[2].style.color = '#000';
         givingbtn[4].style.backgroundColor = '#fff';
         givingbtn[4].style.color = '#000';
         givingbtn[5].style.backgroundColor = '#fff';
         givingbtn[5].style.color = '#000';
       }

       const handleButtonChange1000 = (e) => {
           setGivingAmount(e.target.value);
           console.log(e.target.value);

           
         givingbtn[0].style.backgroundColor = '#fff';
         givingbtn[0].style.color = '#000';
         givingbtn[1].style.backgroundColor = '#fff';
         givingbtn[1].style.color = '#000';
         givingbtn[2].style.backgroundColor = '#fff';
         givingbtn[2].style.color = '#000';
         givingbtn[3].style.backgroundColor = '#fff';
         givingbtn[3].style.color = '#000';
         givingbtn[4].style.backgroundColor = '#135592';
         givingbtn[4].style.color = '#fff';
         givingbtn[5].style.backgroundColor = '#fff';
         givingbtn[5].style.color = '#000';
       }
       const handleButtonChange10000 = (e) => {
           setGivingAmount(e.target.value);
           console.log(e.target.value);

           
         givingbtn[0].style.backgroundColor = '#fff';
         givingbtn[0].style.color = '#000';
         givingbtn[1].style.backgroundColor = '#fff';
         givingbtn[1].style.color = '#000';
         givingbtn[2].style.backgroundColor = '#fff';
         givingbtn[2].style.color = '#000';
         givingbtn[3].style.backgroundColor = '#fff';
         givingbtn[3].style.color = '#000';
         givingbtn[4].style.backgroundColor = '#fff';
         givingbtn[4].style.color = '#000';
         givingbtn[5].style.backgroundColor = '#135592';
         givingbtn[5].style.color = '#fff';
       }

     useEffect(() => {
       parseInt(localStorage.setItem('givings_amount', givings_amount));
       localStorage.setItem('givings_name', givings_name);
       localStorage.setItem('givings_email', givings_email);
       localStorage.setItem('givings_pnum', givings_pnum);
       localStorage.setItem('givings_type', givings_type);
     }, [givings_amount,givings_name,givings_email,givings_pnum,givings_type]);
     
       

      //const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
  
      const navigate = useNavigate();

      const OnCheckForEmptyValues = (data, actions) => {
          if(givings_name === null || givings_email === null || givings_pnum === null 
           || givings_type == "" || givings_amount === null ) {
             setErrorMessages("All Field Are Required");

             return actions.reject();
          } else {
             return actions.resolve();
          }
      }

      const onCreateOrder = (data, actions) => {
       const givings_amount = parseInt(localStorage.getItem('givings_amount'));
       return actions.order.create({
           purchase_units: [{
             amount: {
               currency_code: 'USD',
               value: givings_amount,
             },
           }],
           // application_context: {
             // shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
            //},
         });

         
      }


      const onApproveOrder  = (data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {

           const givingsamount = parseInt(localStorage.getItem('givings_amount'));
           const givingsname = localStorage.getItem('givings_name');
           const givingsemail = localStorage.getItem('givings_email');
           const givingspnum = localStorage.getItem('givings_pnum');
           const givingstype = localStorage.getItem('givings_type');

           const { payer } = details; 
           // Show a success message to your buyer
          
           const givingsreference = details.id;                
           const item = { givingsname, givingsemail, givingspnum, givingstype, givingsamount, givingsreference, givingsstatus };
           
           axios.post(serverurl + "/api/giving", item).then(res => {               
                   setSuccess(true);
           });
               

         });
      }

      const onError = (error) => {
         setErrorMessages("All Fields Are Required and Must Be Valid");
      }

      const onCancel = () => {
       setErrorMessages("You cancelled the transaction");
      }


      //  CARDS

     const OnCheckForEmptyValuesCard = (data, actions) => {
          if(givings_name === null || givings_email === null || givings_pnum === null || givings_type === null || givings_amount === null ) {
             setErrorMessages("All Field Are Required");

             return actions.reject();
          } else {
             return actions.resolve();
          }
      }

      const onCreateOrderCard = (data, actions) => {
       const givings_amount = parseInt(localStorage.getItem('givings_amount'));
       return actions.order.create({
           purchase_units: [{
             amount: {
               currency_code: 'USD',
               value: givings_amount,
             },
           }],
           // application_context: {
             // shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
            //},
         });

         
      }


      const onApproveOrderCard  = (data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {

           const givingsamount = parseInt(localStorage.getItem('givings_amount'));
           const givingsname = localStorage.getItem('givings_name');
           const givingsemail = localStorage.getItem('givings_email');
           const givingspnum = localStorage.getItem('givings_pnum');
           const givingstype = localStorage.getItem('givings_type');

           const { payer } = details; 
           // Show a success message to your buyer
          
           const givingsreference = details.id;                
           const item = { givingsname, givingsemail, givingspnum, givingstype, givingsamount, givingsreference, givingsstatus };
           
           axios.post(serverurl + "/api/giving", item).then(res => {               
                   setSuccess(true);
           });
               

         });
      }

      const onErrorCard = (error) => {
         setErrorMessages("All Fields Are Required and Must Be Valid");
      }

      const onCancelCard = () => {
       setErrorMessages("You cancelled the transaction");
      }


    const [showpaypal, setShowPaypal] = useState(false);
    const [showcard, setShowCard] = useState(false);
    const [showzelle, setShowZelle] = useState(false);
    const [paymentplatform, setPaymentPlatform] = useState();
    const [givingscat, setGivingsCat] = useState();

    const handleSelectChange = (e) => {
      //setGivingsCat(e.target.value);

      let givingcatinput = document.getElementById('showhidegivingcat');
      let givinginputid = document.getElementById('givinginputid');    

      
      if(e.target.value === "Others") {
          givinginputid.value = '';
          givingcatinput.style.display = 'block';
          givinginputid.style.display = 'block';
           givingcatinput.style.width = '100%';
           givinginputid.style.width = '100%';
      } else {
           givinginputid.value = e.target.value;
           givingcatinput.style.display = 'none';
           givinginputid.style.display = 'none';
           givingcatinput.style.width = '100%';
           givinginputid.style.width = '100%';
          
      }

   }


   

    return (
 
        <div>
  

            <div>
                <div style={{ position: 'relative' }}>
                    <Image fluid src="images/headerbanner.png" alt="Card image" id="bannerimg" />
                    <div id="banneroverlay">
                        <div id="bannerid" className='text-center'>
                            <h4>GIVE</h4>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>

            <br></br><br></br>


            <div>
                <Container>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Card id="deptcard" className='givingcard'>
                                {/*<Card.Header style={{ backgroundColor: '#135592', color: '#fff', fontSize: '20px', textAlign: 'center' }}>
                                    Giving
                                </Card.Header>*/}
                                <Card.Body>
                                    <Form>
                                       <div>
                                        <InputGroup>
                                        <Form.Select type="text" size="sm" style={{ fontSize: '16px', padding: '15px' }}
                                                value={givingscat} onChange={handleSelectChange} required id="givingformid">
                                                <option value='' >Select Giving Category</option>
                                                {
                                                givingcategory && givingcategory.length > 0 && givingcategory.map((givingCatData, index) => {
                                                   return <>
                                                      <option value={givingCatData.givingcategories_name}>
                                                        {givingCatData.givingcategories_name}
                                                      </option>
                                                   </>
                                                })
                                               
                                                }
                                        </Form.Select>
                                        </InputGroup>
                                        <br></br>
                                        <InputGroup className="mb-3" controlId="" id='showhidegivingcat'>      
                                            <Form.Control type="text" size="lg" 
                                            name="givings_type" placeholder="Specify the Purpose of Giving" style={{ fontSize: '16px', padding: '15px' }}
                                                value={givings_type} onChange={handleGivingCatInputChange} required id="givinginputid"  />
                                      
                                        </InputGroup>
                                        </div>                                    

                                    
                                     <h6 className=''>Amount</h6>
                                   
                                   <div className=''>
                                    <ButtonGroup className="me-2" aria-label="First group" style={{width:'100%'}}>
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn" 
                                        onClick={handleButtonChange50} value={50} >
                                            $50
                                        </Button>
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn"
                                        onClick={handleButtonChange100} value={100} >
                                            $100
                                        </Button>
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn" 
                                            onClick={handleButtonChange200} value={200}>
                                             $200
                                        </Button>

                                    </ButtonGroup>
 
                                   </div>
                            
                                    <br></br>        
                                    <div className='text-center'>
                                        <ButtonGroup className="me-2" aria-label="First group" style={{width:'100%'}}>
                                           
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn" 
                                            onClick={handleButtonChange500} value={500}>
                                                $500
                                        </Button>
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn" 
                                            onClick={handleButtonChange1000} value={1000} >
                                               $1000
                                        </Button>
                                        <Button to="#" className='btn btn-danger givingbtn' id="givingbtn" 
                                            onClick={handleButtonChange10000} value={10000}>
                                                $10000
                                        </Button>

                                        </ButtonGroup>

                                        <br></br><br></br>

                                        <InputGroup className="mb-3" controlId="">
                                        <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control type="text" size="lg" placeholder="Enter Amount" name="givings_amount"
                                                value={givings_amount} 
                                                onChange={handleInputChange} required id="givingformid" />
                                        </InputGroup>
                                    </div>
                                                                        
                                        {/*<InputGroup>
                                        <Form.Select type="text" size="lg" style={{ fontSize: '16px', padding: '15px' }}
                                                value={paymentplatform} onChange={handleSelectChange} required id='givingformid'>
                                                <option value=''>Select Payment Platform</option>
                                                <option value='paypal'>PayPal</option>
                                                <option value='card'>Card</option>
                                                <option value='zelle'>Others</option>
                                                
                                        </Form.Select>
                                        </InputGroup>
                                        */}
                                        <br></br>
                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>
                                            <Form.Control type="text" size="lg" placeholder="Full Name" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={givings_name} onChange={(e) => setGivingName(e.target.value)} required id="givingformid" />
                                        </InputGroup>

                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>      
                                            <Form.Control type="email" size="lg" placeholder="Email" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={givings_email} onChange={(e) => setGivingEmail(e.target.value)} required id="givingformid"  />
                                      
                                        </InputGroup>

                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>
                                            <Form.Control type="text" size="lg" placeholder="Pnone Number" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={givings_pnum} onChange={(e) => setGivingPnum(e.target.value)} required id="givingformid" />
                                        </InputGroup>

                                    </Form>

                          

                                    <div>

                                    {
                                    success ? 
                                    (
                                      <div className='alert alert-success'>Payment made successfully</div>
                                    ) :
                                    errormessages === '' ?
                                    (
                                         ''
                                    ) :   <div className='alert alert-danger'>{errormessages}</div>
                                    }

                                    </div>

                                    <div>

                                    {
                                    showzelle ? 
                                    (
                                    <div className='alert alert-success' style={{ fontWeight:'bold' }}>
                                        Make the payment of ${givings_amount} to any of the following account:
                                        <p>Zelle account: giving@kccconline.org</p>
                                        <p>KCCC PayPal: prm@kccconline.org</p>
                                        <p>CashApp:  $KCCCAURORA1</p>
                                        <p>Pay your tithe & offerings over the phone – You may also call Church Office Line on 7208591737</p>
                                        <p>Drop off your Giving Checks at the Church office @ 1391 Oswego Street, Aurora CO</p>
                                    </div>
                                    ) : ''
                                    }

                                    </div>

            
                                    {/*<PayPalButton
                                        createOrder={(data,actions) => createOrder(data, actions)}
                                        onApprove={(data,actions) => onApprove(data, actions)}
                                     />*/}

                                <div className='row'>
                                  <div className='col-lg-6'>
                                    <PayPalScriptProvider 
                                    options={{ clientId: "AQEZ7zUUICwpFbxCkYk-MJUPTtusnsmhRGeJ8tfkMzMHPk_x6RHLMbC5LaaPnXhvBvvTGwR0TUBGzRAJ" }}><PayPalButtons
                                        onClick={OnCheckForEmptyValues}
                                        createOrder={onCreateOrder}
                                        onApprove={onApproveOrder}
                                        onError={onError}
                                        onCancel={onCancel}

                                        style={{
                                            tagline: false,
                                            color: 'blue',
                                            layout:'vertical',
                                            label: 'pay',
                                            height: 48,
                                            disableMaxWidth: true,
                                        }}

                                        fundingSource={FUNDING.PAYPAL}
                                            
                                        /></PayPalScriptProvider>
                                    </div>

                                    <div className='col-lg-6'>
                                        <PayPalScriptProvider 
                                        options={{ clientId: "AQEZ7zUUICwpFbxCkYk-MJUPTtusnsmhRGeJ8tfkMzMHPk_x6RHLMbC5LaaPnXhvBvvTGwR0TUBGzRAJ" }}><PayPalButtons
                                            onClick={OnCheckForEmptyValuesCard}
                                            createOrder={onCreateOrderCard}
                                            onApprove={onApproveOrderCard}
                                            onError={onErrorCard}
                                            onCancel={onCancelCard}

                                            style={{
                                                tagline: false,
                                                color: 'white',
                                                layout:'horizontal',
                                                label: 'pay',
                                                height: 48,
                                                disableMaxWidth: true,
                                            }}

                                            fundingSource={FUNDING.CARD}
                                                
                                            /></PayPalScriptProvider>
                                        </div>
                                    </div>
                                    
                                       
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
            </div>

 
            <br></br><br></br>

           



        </div >
    )
}
