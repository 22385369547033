import { React, useEffect, useState } from 'react'
import { Card, Image, InputGroup } from 'react-bootstrap';
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Button, ButtonGroup, ButtonToolbar, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideoCamera, faShareNodes, faDownload, faFileAudio, faUser, faLocation, faClock, faPerson, faArrowLeft, faAngleRight, faBook, faMessage } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faPhone, faMapLocationDot, faMapLocation, faAddressCard, faAddressBook } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { serverurl } from '../../providers/ServerUrl';
//import ReactDOM from "react-dom";
import './Donation.css';

import { useSignal } from '@preact/signals-react';

import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { PaymentImages } from '../../components/PaymentImages';


export const Donation = () => {


    /**********************************************
      GET GIVING CATEGORY DATA FROM THE API
    **********************************************/

    const [donationcategory, setDonationsCategory] = useState();


   const fetchDonationCategoryData = () => {
       return axios.get(serverurl + "/api/donationcategory")
           .then((response) => setDonationsCategory(response.data['donationcategories']));

   };

   useEffect(() => {
       fetchDonationCategoryData();
   }, [])


   /**********************************************
      POST CONTACT FORM DATA TO THE API
    **********************************************/
      const [show, setShow] = useState(false);
      const [success, setSuccess] = useState(false);
      const [errormessages, setErrorMessages] = useState('');
      const [orderid, setOrderId] = useState(false);
  
      const [donations_name, setDonationName] = useState('Dummy Name');
      const [donations_email, setDonationEmail] = useState('dummy@gmail.com');
      const [donations_pnum, setDonationPnum] = useState('09012345678');
      const [donations_type, setDonationCategory] = useState();
      const [donations_amount, setDonationAmount] = useState();

      const donationsstatus = "Paid";

      //const donations_amount  = useSignal();

      const handleInputChange = (e) => {
         setDonationAmount(e.target.value);
      }

      const handleDonationCatInputChange = (e) => {
         setDonationCategory(e.target.value);
    }

      let donationbtn = document.getElementsByClassName('donationbtn');

      const handleButtonChange50 = (e) => {
         setDonationAmount(e.target.value);
         console.log(e.target.value);

         donationbtn[0].style.backgroundColor = '#135592';
         donationbtn[0].style.color = '#fff';
         donationbtn[1].style.backgroundColor = '#fff';
         donationbtn[1].style.color = '#000';
         donationbtn[2].style.backgroundColor = '#fff';
         donationbtn[2].style.color = '#000';
         donationbtn[3].style.backgroundColor = '#fff';
         donationbtn[3].style.color = '#000';
         donationbtn[4].style.backgroundColor = '#fff';
         donationbtn[4].style.color = '#000';
         donationbtn[5].style.backgroundColor = '#fff';
         donationbtn[5].style.color = '#000';
      }
      const handleButtonChange100 = (e) => {
       setDonationAmount(e.target.value);
       console.log(e.target.value);

         
       donationbtn[0].style.backgroundColor = '#fff';
       donationbtn[0].style.color = '#000';
       donationbtn[1].style.backgroundColor = '#135592';
       donationbtn[1].style.color = '#fff';
       donationbtn[2].style.backgroundColor = '#fff';
       donationbtn[2].style.color = '#000';
       donationbtn[3].style.backgroundColor = '#fff';
       donationbtn[3].style.color = '#000';
       donationbtn[4].style.backgroundColor = '#fff';
       donationbtn[4].style.color = '#000';
       donationbtn[5].style.backgroundColor = '#fff';
       donationbtn[5].style.color = '#000';
       }

       const handleButtonChange200 = (e) => {
           setDonationAmount(e.target.value);
           console.log(e.target.value);
           
         donationbtn[0].style.backgroundColor = '#fff';
         donationbtn[0].style.color = '#000';
         donationbtn[1].style.backgroundColor = '#fff';
         donationbtn[1].style.color = '#000';
         donationbtn[3].style.backgroundColor = '#fff';
         donationbtn[3].style.color = '#000';
         donationbtn[2].style.backgroundColor = '#135592';
         donationbtn[2].style.color = '#fff';
         donationbtn[4].style.backgroundColor = '#fff';
         donationbtn[4].style.color = '#000';
         donationbtn[5].style.backgroundColor = '#fff';
         donationbtn[5].style.color = '#000';
       }
       const handleButtonChange500 = (e) => {
           setDonationAmount(e.target.value);
           console.log(e.target.value);

           
         donationbtn[0].style.backgroundColor = '#fff';
         donationbtn[0].style.color = '#000';
         donationbtn[1].style.backgroundColor = '#fff';
         donationbtn[1].style.color = '#000';
         donationbtn[3].style.backgroundColor = '#135592';
         donationbtn[3].style.color = '#fff';
         donationbtn[2].style.backgroundColor = '#fff';
         donationbtn[2].style.color = '#000';
         donationbtn[4].style.backgroundColor = '#fff';
         donationbtn[4].style.color = '#000';
         donationbtn[5].style.backgroundColor = '#fff';
         donationbtn[5].style.color = '#000';
       }

       const handleButtonChange1000 = (e) => {
           setDonationAmount(e.target.value);
           console.log(e.target.value);

           
         donationbtn[0].style.backgroundColor = '#fff';
         donationbtn[0].style.color = '#000';
         donationbtn[1].style.backgroundColor = '#fff';
         donationbtn[1].style.color = '#000';
         donationbtn[2].style.backgroundColor = '#fff';
         donationbtn[2].style.color = '#000';
         donationbtn[3].style.backgroundColor = '#fff';
         donationbtn[3].style.color = '#000';
         donationbtn[4].style.backgroundColor = '#135592';
         donationbtn[4].style.color = '#fff';
         donationbtn[5].style.backgroundColor = '#fff';
         donationbtn[5].style.color = '#000';
       }
       const handleButtonChange10000 = (e) => {
           setDonationAmount(e.target.value);
           console.log(e.target.value);

           
         donationbtn[0].style.backgroundColor = '#fff';
         donationbtn[0].style.color = '#000';
         donationbtn[1].style.backgroundColor = '#fff';
         donationbtn[1].style.color = '#000';
         donationbtn[2].style.backgroundColor = '#fff';
         donationbtn[2].style.color = '#000';
         donationbtn[3].style.backgroundColor = '#fff';
         donationbtn[3].style.color = '#000';
         donationbtn[4].style.backgroundColor = '#fff';
         donationbtn[4].style.color = '#000';
         donationbtn[5].style.backgroundColor = '#135592';
         donationbtn[5].style.color = '#fff';
       }

     useEffect(() => {
       parseInt(localStorage.setItem('donations_amount', donations_amount));
       localStorage.setItem('donations_name', donations_name);
       localStorage.setItem('donations_email', donations_email);
       localStorage.setItem('donations_pnum', donations_pnum);
       localStorage.setItem('donations_type', donations_type);
     }, [donations_amount,donations_name,donations_email,donations_pnum,donations_type]);
     
       

      //const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
  
      const navigate = useNavigate();

      const OnCheckForEmptyValues = (data, actions) => {
          if(donations_name === null || donations_email === null || donations_pnum === null 
           || donations_type == "" || donations_amount === null ) {
             setErrorMessages("All Field Are Required");

             return actions.reject();
          } else {
             return actions.resolve();
          }
      }

      const onCreateOrder = (data, actions) => {
       const donations_amount = parseInt(localStorage.getItem('donations_amount'));
       return actions.order.create({
           purchase_units: [{
             amount: {
               currency_code: 'USD',
               value: donations_amount,
             },
           }],
           // application_context: {
             // shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
            //},
         });

         
      }


      const onApproveOrder  = (data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {

           const donationsamount = parseInt(localStorage.getItem('donations_amount'));
           const donationsname = localStorage.getItem('donations_name');
           const donationsemail = localStorage.getItem('donations_email');
           const donationspnum = localStorage.getItem('donations_pnum');
           const donationstype = localStorage.getItem('donations_type');

           const { payer } = details; 
           // Show a success message to your buyer
          
           const donationsreference = details.id;                
           const item = { donationsname, donationsemail, donationspnum, donationstype, donationsamount, donationsreference, donationsstatus };
           
           axios.post(serverurl + "/api/donation", item).then(res => {               
                   setSuccess(true);
           });
               

         });
      }

      const onError = (error) => {
         setErrorMessages("All Fields Are Required and Must Be Valid");
      }

      const onCancel = () => {
       setErrorMessages("You cancelled the transaction");
      }


      //  CARDS

     const OnCheckForEmptyValuesCard = (data, actions) => {
          if(donations_name === null || donations_email === null || donations_pnum === null || donations_type === null || donations_amount === null ) {
             setErrorMessages("All Field Are Required");

             return actions.reject();
          } else {
             return actions.resolve();
          }
      }

      const onCreateOrderCard = (data, actions) => {
       const donations_amount = parseInt(localStorage.getItem('donations_amount'));
       return actions.order.create({
           purchase_units: [{
             amount: {
               currency_code: 'USD',
               value: donations_amount,
             },
           }],
           // application_context: {
             // shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
            //},
         });

         
      }


      const onApproveOrderCard  = (data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {

           const donationsamount = parseInt(localStorage.getItem('donations_amount'));
           const donationsname = localStorage.getItem('donations_name');
           const donationsemail = localStorage.getItem('donations_email');
           const donationspnum = localStorage.getItem('donations_pnum');
           const donationstype = localStorage.getItem('donations_type');

           const { payer } = details; 
           // Show a success message to your buyer
          
           const donationsreference = details.id;                
           const item = { donationsname, donationsemail, donationspnum, donationstype, donationsamount, donationsreference, donationsstatus };
           
           axios.post(serverurl + "/api/donation", item).then(res => {               
                   setSuccess(true);
           });
               

         });
      }

      const onErrorCard = (error) => {
         setErrorMessages("All Fields Are Required and Must Be Valid");
      }

      const onCancelCard = () => {
       setErrorMessages("You cancelled the transaction");
      }


    const [showpaypal, setShowPaypal] = useState(false);
    const [showcard, setShowCard] = useState(false);
    const [showzelle, setShowZelle] = useState(false);
    const [paymentplatform, setPaymentPlatform] = useState();
    const [donationscat, setDonationsCat] = useState();

    const handleSelectChange = (e) => {
      //setDonationsCat(e.target.value);

      let donationcatinput = document.getElementById('showhidedonationcat');
      let donationinputid = document.getElementById('donationinputid');    

      
      if(e.target.value === "Others") {
          donationinputid.value = '';
          donationcatinput.style.display = 'block';
          donationinputid.style.display = 'block';
           donationcatinput.style.width = '100%';
           donationinputid.style.width = '100%';
      } else {
           donationinputid.value = e.target.value;
           donationcatinput.style.display = 'none';
           donationinputid.style.display = 'none';
           donationcatinput.style.width = '100%';
           donationinputid.style.width = '100%';
          
      }

   }
   

    return (
 
        <div>
  

            <div>
                <div style={{ position: 'relative' }}>
                    <Image fluid src="images/headerbanner.png" alt="Card image" id="bannerimg" />
                    <div id="banneroverlay">
                        <div id="bannerid" className='text-center'>
                            <h4>DONATE</h4>
                        </div>
                    </div>
                </div>
            </div>

            <br></br>

            <div>
                <Container>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <Card id="deptcard" className='donationcard'>
                                {/*<Card.Header style={{ backgroundColor: '#135592', color: '#fff', fontSize: '20px', textAlign: 'center' }}>
                                    Donation
                                </Card.Header>*/}
                                <Card.Body>
                                    <Form>
                                       <div>
                                        <InputGroup>
                                        <Form.Select type="text" size="sm" style={{ fontSize: '16px', padding: '15px' }}
                                                value={donationscat} onChange={handleSelectChange} required id="donationformid">
                                                <option value='' >Select Donation Category</option>
                                                {
                                                donationcategory && donationcategory.length > 0 && donationcategory.map((donationCatData, index) => {
                                                   return <>
                                                      <option value={donationCatData.donationcategories_name}>
                                                        {donationCatData.donationcategories_name}
                                                      </option>
                                                   </>
                                                })
                                               
                                                }
                                        </Form.Select>
                                        </InputGroup>
                                        <br></br>
                                        <InputGroup className="mb-3" controlId="" id='showhidedonationcat'>      
                                            <Form.Control type="text" size="lg" 
                                            name="donations_type" placeholder="Specify the Purpose of Donation" style={{ fontSize: '16px', padding: '15px' }}
                                                value={donations_type} onChange={handleDonationCatInputChange} required id="donationinputid"  />
                                      
                                        </InputGroup>
                                        </div>                                    

                                    
                                     <h6 className=''>Amount</h6>
                                   
                                   <div className=''>
                                    <ButtonGroup className="me-2" aria-label="First group" style={{width:'100%'}}>
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn" 
                                        onClick={handleButtonChange50} value={50} >
                                            $50
                                        </Button>
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn"
                                        onClick={handleButtonChange100} value={100} >
                                            $100
                                        </Button>
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn" 
                                            onClick={handleButtonChange200} value={200}>
                                             $200
                                        </Button>

                                    </ButtonGroup>
 
                                   </div>
                            
                                    <br></br>        
                                    <div className='text-center'>
                                        <ButtonGroup className="me-2" aria-label="First group" style={{width:'100%'}}>
                                           
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn" 
                                            onClick={handleButtonChange500} value={500}>
                                                $500
                                        </Button>
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn" 
                                            onClick={handleButtonChange1000} value={1000} >
                                               $1000
                                        </Button>
                                        <Button to="#" className='btn btn-danger donationbtn' id="donationbtn" 
                                            onClick={handleButtonChange10000} value={10000}>
                                                $10000
                                        </Button>

                                        </ButtonGroup>

                                        <br></br><br></br>

                                        <InputGroup className="mb-3" controlId="">
                                        <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control type="text" size="lg" placeholder="Enter Amount" name="donations_amount"
                                                value={donations_amount} 
                                                onChange={handleInputChange} required id="donationformid" />
                                        </InputGroup>
                                    </div>
                                                                        
                                        {/*<InputGroup>
                                        <Form.Select type="text" size="lg" style={{ fontSize: '16px', padding: '15px' }}
                                                value={paymentplatform} onChange={handleSelectChange} required id='donationformid'>
                                                <option value=''>Select Payment Platform</option>
                                                <option value='paypal'>PayPal</option>
                                                <option value='card'>Card</option>
                                                <option value='zelle'>Others</option>
                                                
                                        </Form.Select>
                                        </InputGroup>
                                        */}
                                        <br></br>
                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>
                                            <Form.Control type="text" size="lg" placeholder="Full Name" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={donations_name} onChange={(e) => setDonationName(e.target.value)} required id="donationformid" />
                                        </InputGroup>

                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>      
                                            <Form.Control type="email" size="lg" placeholder="Email" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={donations_email} onChange={(e) => setDonationEmail(e.target.value)} required id="donationformid"  />
                                      
                                        </InputGroup>

                                        <InputGroup className="mb-3" controlId="" style={{ display:'none' }}>
                                            <Form.Control type="text" size="lg" placeholder="Pnone Number" style={{ fontSize: '16px', padding: '15px', display:'none' }}
                                                value={donations_pnum} onChange={(e) => setDonationPnum(e.target.value)} required id="donationformid" />
                                        </InputGroup>

                                    </Form>

                          

                                    <div>

                                    {
                                    success ? 
                                    (
                                      <div className='alert alert-success'>Payment made successfully</div>
                                    ) :
                                    errormessages === '' ?
                                    (
                                         ''
                                    ) :   <div className='alert alert-danger'>{errormessages}</div>
                                    }

                                    </div>

                                    <div>

                                    {
                                    showzelle ? 
                                    (
                                    <div className='alert alert-success' style={{ fontWeight:'bold' }}>
                                        Make the payment of ${donations_amount} to any of the following account:
                                        <p>Zelle account: donation@kccconline.org</p>
                                        <p>KCCC PayPal: prm@kccconline.org</p>
                                        <p>CashApp:  $KCCCAURORA1</p>
                                        <p>Pay your tithe & offerings over the phone – You may also call Church Office Line on 7208591737</p>
                                        <p>Drop off your Giving Checks at the Church office @ 1391 Oswego Street, Aurora CO</p>
                                    </div>
                                    ) : ''
                                    }

                                    </div>

            
                                    {/*<PayPalButton
                                        createOrder={(data,actions) => createOrder(data, actions)}
                                        onApprove={(data,actions) => onApprove(data, actions)}
                                     />*/}

                                <div className='row'>
                                  <div className='col-lg-6'>
                                    <PayPalScriptProvider 
                                    options={{ clientId: "AQEZ7zUUICwpFbxCkYk-MJUPTtusnsmhRGeJ8tfkMzMHPk_x6RHLMbC5LaaPnXhvBvvTGwR0TUBGzRAJ" }}><PayPalButtons
                                        onClick={OnCheckForEmptyValues}
                                        createOrder={onCreateOrder}
                                        onApprove={onApproveOrder}
                                        onError={onError}
                                        onCancel={onCancel}

                                        style={{
                                            tagline: false,
                                            color: 'blue',
                                            layout:'vertical',
                                            label: 'pay',
                                            height: 48,
                                            disableMaxWidth: true,
                                        }}

                                        fundingSource={FUNDING.PAYPAL}
                                            
                                        /></PayPalScriptProvider>
                                    </div>

                                    <div className='col-lg-6'>
                                        <PayPalScriptProvider 
                                        options={{ clientId: "AQEZ7zUUICwpFbxCkYk-MJUPTtusnsmhRGeJ8tfkMzMHPk_x6RHLMbC5LaaPnXhvBvvTGwR0TUBGzRAJ" }}><PayPalButtons
                                            onClick={OnCheckForEmptyValuesCard}
                                            createOrder={onCreateOrderCard}
                                            onApprove={onApproveOrderCard}
                                            onError={onErrorCard}
                                            onCancel={onCancelCard}

                                            style={{
                                                tagline: false,
                                                color: 'white',
                                                layout:'horizontal',
                                                label: 'pay',
                                                height: 48,
                                                disableMaxWidth: true,
                                            }}

                                            fundingSource={FUNDING.CARD}
                                                
                                            /></PayPalScriptProvider>
                                        </div>
                                    </div>
                                    
                                       
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
            </div>

 

            <br></br><br></br>



        </div >
    )
}
